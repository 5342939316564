export const chinaAccessFeatures = () => [
  {
    title: 'chinaAccessPage.section6.list1.0.title',
    content: 'chinaAccessPage.section6.list1.0.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Crafted-for-any-CDN.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.1.title',
    content: 'chinaAccessPage.section6.list1.1.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Encrypt-everything.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.2.title',
    content: 'chinaAccessPage.section6.list1.2.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Quick-deployment.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.3.title',
    content: 'chinaAccessPage.section6.list1.3.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Ever-updating.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.4.title',
    content: 'chinaAccessPage.section6.list1.4.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Ultrafast.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.5.title',
    content: 'chinaAccessPage.section6.list1.5.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Immediate-access.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.6.title',
    content: 'chinaAccessPage.section6.list1.6.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Full-transparency-everywhere.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.7.title',
    content: 'chinaAccessPage.section6.list1.7.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Easy-management.png'
  },
  {
    title: 'chinaAccessPage.section6.list1.8.title',
    content: 'chinaAccessPage.section6.list1.8.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Fully-automated.png'
  }
]
export const marketplaceFeatures = () => [
  {
    title: 'marketplacePage.section3.list1.0.title',
    content: 'marketplacePage.section3.list1.0.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_App-store-like.png'
  },
  {
    title: 'marketplacePage.section3.list1.1.title',
    content: 'marketplacePage.section3.list1.1.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_On-demand.png'
  },
  {
    title: 'marketplacePage.section3.list1.2.title',
    content: 'marketplacePage.section3.list1.2.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Time-saving.png'
  }
]
export const enterpriseSupportFeatures = () => [
  {
    title: 'enterpriseSupportPage.section3.list1.0.title',
    content: 'enterpriseSupportPage.section3.list1.0.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Customer-oriented.png'
  },
  {
    title: 'enterpriseSupportPage.section3.list1.1.title',
    content: 'enterpriseSupportPage.section3.list1.1.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Beyond-security.png'
  },
  {
    title: 'enterpriseSupportPage.section3.list1.2.title',
    content: 'enterpriseSupportPage.section3.list1.2.description',
    imageSrc: '	https://www.mlytics.com/wp-content/uploads/2021/08/icon_Non-stop-support.png'
  }
]
export const streamChinaAccessFeatures = () => [
  {
    title: 'videoStreamChinaPage.section5.list1.0.title',
    content: 'videoStreamChinaPage.section5.list1.0.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Full-transparency-everywhere.png'
  },
  {
    title: 'videoStreamChinaPage.section5.list1.1.title',
    content: 'videoStreamChinaPage.section5.list1.1.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Easy-management.png'
  },
  {
    title: 'videoStreamChinaPage.section5.list1.2.title',
    content: 'videoStreamChinaPage.section5.list1.2.description',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Fully-automated.png'
  }
]
export const streamToChinaAdvantages = () => [
  {
    title: 'videoStreamChinaPage.section2.list1.0.title',
    content: 'videoStreamChinaPage.section2.list1.0.description',
    icon: 'https://static.mlytics.com/images/website/icon_compliance_knowhow.svg',
    image: 'https://static.mlytics.com/images/website/compliance_knowhow.png',
    clickId: '2cn_need_01_icp'
  },
  {
    title: 'videoStreamChinaPage.section2.list1.1.title',
    content: 'videoStreamChinaPage.section2.list1.1.description',
    icon: 'https://static.mlytics.com/images/website/icon_delivery_to_china.svg',
    image: 'https://static.mlytics.com/images/website/stable_content_delivery_to_china.png',
    clickId: '2cn_need_02_stable'
  },
  {
    title: 'videoStreamChinaPage.section2.list1.2.title',
    content: 'videoStreamChinaPage.section2.list1.2.description',
    icon: 'https://static.mlytics.com/images/website/icon_cdn_providers.svg',
    image: 'https://static.mlytics.com/images/website/leading_china_cdn_providers.png',
    clickId: '2cn_need_03_provider'
  }
]
export const applicationList = () => [
  {
    title: 'videoStreamChinaPage.section3.list1.0.title',
    type: 'VOD',
    bgImage: 'https://static.mlytics.com/images/website/application_ott_china_access.png',
    image: 'https://static.mlytics.com/images/website/case_ott_china_access.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.0.content.0',
      'videoStreamChinaPage.section3.list1.0.content.1',
      'videoStreamChinaPage.section3.list1.0.content.2',
      'videoStreamChinaPage.section3.list1.0.content.3',
      'videoStreamChinaPage.section3.list1.0.content.4'
    ],
    clickIds: ['2cn_story_01_OTT', '2cn_story_01_01_try', '2cn_story_01_02_demo']
  },
  {
    title: 'videoStreamChinaPage.section3.list1.1.title',
    type: 'VOD',
    bgImage: 'https://static.mlytics.com/images/website/application_short_vod_social_app.png',
    image: 'https://static.mlytics.com/images/website/case_short_vod_social_app.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.1.content.0',
      'videoStreamChinaPage.section3.list1.1.content.1',
      'videoStreamChinaPage.section3.list1.1.content.2',
      'videoStreamChinaPage.section3.list1.1.content.3',
      'videoStreamChinaPage.section3.list1.1.content.4'
    ],
    clickIds: ['2cn_story_02_VOD', '2cn_story_02_01_try', '2cn_story_02_02_demo']
  },
  {
    title: 'videoStreamChinaPage.section3.list1.2.title',
    type: 'live-streaming',
    bgImage: 'https://static.mlytics.com/images/website/application_live_stream.png',
    image: 'https://static.mlytics.com/images/website/case_live_stream.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.2.content.0',
      'videoStreamChinaPage.section3.list1.2.content.1',
      'videoStreamChinaPage.section3.list1.2.content.2',
      'videoStreamChinaPage.section3.list1.2.content.3'
    ],
    clickIds: ['2cn_story_03_live', '2cn_story_03_01_try', '2cn_story_03_02_demo']
  },
  {
    title: 'videoStreamChinaPage.section3.list1.3.title',
    type: 'live-streaming',
    bgImage: 'https://static.mlytics.com/images/website/application_sport_stream.png',
    image: 'https://static.mlytics.com/images/website/case_sport_stream.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.3.content.0',
      'videoStreamChinaPage.section3.list1.3.content.1',
      'videoStreamChinaPage.section3.list1.3.content.2',
      'videoStreamChinaPage.section3.list1.3.content.3'
    ],
    clickIds: ['2cn_story_04_sport', '2cn_story_04_01_try', '2cn_story_04_02_demo']
  },
  {
    title: 'videoStreamChinaPage.section3.list1.4.title',
    type: 'live-streaming',
    bgImage: 'https://static.mlytics.com/images/website/application_live_shopping.png',
    image: 'https://static.mlytics.com/images/website/case_live_shopping.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.4.content.0',
      'videoStreamChinaPage.section3.list1.4.content.1',
      'videoStreamChinaPage.section3.list1.4.content.2',
      'videoStreamChinaPage.section3.list1.4.content.3'
    ],
    clickIds: ['2cn_story_05_shop', '2cn_story_05_01_try', '2cn_story_05_02_demo']
  },
  {
    title: 'videoStreamChinaPage.section3.list1.5.title',
    type: 'enterprise',
    bgImage: 'https://static.mlytics.com/images/website/application_train_and_workshops.png',
    image: 'https://static.mlytics.com/images/website/case_train_and_workshops.png',
    descriptions: [
      'videoStreamChinaPage.section3.list1.5.content.0',
      'videoStreamChinaPage.section3.list1.5.content.1',
      'videoStreamChinaPage.section3.list1.5.content.2'
    ],
    clickIds: ['2cn_story_06_workshop', '2cn_story_06_01_try', '2cn_story_06_02_demo']
  }
]
export const audienceAnalyticsFeatures = () => [
  {
    title: 'videoStreamChinaPage.section4.list1.0.accordion.0.title',
    content: 'videoStreamChinaPage.section4.list1.0.accordion.0.content'
  },
  {
    title: 'videoStreamChinaPage.section4.list1.0.accordion.1.title',
    content: 'videoStreamChinaPage.section4.list1.0.accordion.1.content'
  },
  {
    title: 'videoStreamChinaPage.section4.list1.0.accordion.2.title',
    content: 'videoStreamChinaPage.section4.list1.0.accordion.2.content'
  }
]

export const ctaClickIds = [
  '2cn_banner_01_try',
  '2cn_banner_02_demo',
  '2cn_feature_01_01_try',
  '2cn_feature_01_02_demo',
  '2cn_feature_02_01_try',
  '2cn_feature_02_02_demo',
  '2cn_feature_03_01_try',
  '2cn_feature_03_02_demo',
  '2cn_feature_04_01_try',
  '2cn_feature_04_02_demo',
  '2cn_support_01_try',
  '2cn_support_02_demo'
]
